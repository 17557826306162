<template>
  <div id="TheTeamReport">
    <van-nav-bar
      :fixed="true"
      z-index="10"
      placeholder
      :title="$t('My.PersonalInformation.TheTeamReport.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="header">
      <span class="button-Time" @click="DateEvent(1)">{{ TimeOne }}</span>
      <span class="Text">{{
        $t('My.PersonalInformation.TheTeamReport.To')
      }}</span>
      <span class="button-Time" @click="DateEvent(2)">{{ TimeTwo }}</span>
      <van-button @click="search" icon="search" type="default">{{
        $t('My.PersonalInformation.TheTeamReport.search')
      }}</van-button>
    </div>

    <van-grid :column-num="2">
      <van-grid-item
        v-for="(item, index) in TabelData"
        :key="index"
        class="gridContent"
      >
        <span>{{ $t(item.name) }}</span>
        <span>{{ item.data }}</span>
      </van-grid-item>
    </van-grid>

    <!-- <van-tabs v-model="active" @click="handleClick">
      <van-tab :title="$t('My.PersonalInformation.TheTeamReport.Level1')">
        <div class="tabs-item">
        </div>
      </van-tab>
      <van-tab :title="$t('My.PersonalInformation.TheTeamReport.Level2')">
        <div class="tabs-item">
        </div>
      </van-tab>
      <van-tab :title="$t('My.PersonalInformation.TheTeamReport.Level3')">
        <div class="tabs-item">
        </div>
      </van-tab>
    </van-tabs> -->
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
        duration="0"
        v-model="currentDate"
        confirm-button-text="OK"
        :cancel-button-text="$t('My.PersonalInformation.TheTeamReport.Cancel')"
        type="date"
        title="DatePicker"
        @confirm="ConfirmEvent"
        @cancel="CancelEvent"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Toast
} from 'vant'
import { REQUEST_API } from '@/http/api'
import { getTimeStamp } from '@/utils/tools'
import { countTime, getMyTime, deciTurnMoney } from '@/utils/tools'
export default {
  name: 'TheTeamReport',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
  data() {
    return {
      teamData: '',
      show: false, // 控制日期按钮弹出框的
      // 日期选择的值
      TimeOne: '',
      TimeTwo: '',
      start_time: '', // 开始时间
      end_time: '', // 结束时间
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2030, 12, 31),
      currentDate: new Date(),
      type: '', // 控制当前时间点击状态为哪一个
      active: 0, // 标签选中的值
      TabelData: [
        {
          name: 'My.PersonalInformation.TheTeamReport.balance',
          data: '0',
          key: 'team_money',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.turnover',
          data: '0',
          key: 'team_transaction',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.recharge',
          data: '0',
          key: 'team_reward',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.withdrawal',
          data: '0',
          key: 'team_payout',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.charge',
          data: '0',
          key: 'team_palyer_num',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.people',
          data: '0',
          key: 'team_transaction_palyer_num',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.members',
          data: '0',
          key: 'team_palyer_num',
        },
        {
          name: 'My.PersonalInformation.TheTeamReport.NewPeople',
          data: '0',
          key: 'team_new_palyer_num_transaction',
        },
      ], // 列表渲染的数据
    }
  },
  created() {
    this.team()
  },
  methods: {
    // 搜索
    search() {
      if(this.TimeOne && this.TimeTwo){
        if(getTimeStamp(this.TimeOne) > getTimeStamp(this.TimeTwo)){
          Toast(this.$t('Hint.failed'))
        } else {
          this.team()
        }
      }
    },
    // 团队API
    team() {
      let data = {
        cmd: '200021',
        uid: this.module.UIDEvent(),
        start_time: getTimeStamp(this.TimeOne), // 开始时间
        end_time: getTimeStamp(this.TimeTwo), // 结束时间
      }
      REQUEST_API(data).then((res) => {
        let dataList = res.data.notifyList[0].data
        console.log(dataList)
        for (let i = 0; i < this.TabelData.length; i++) {
          if(i < 4){
            this.TabelData[i].data = deciTurnMoney(dataList[this.TabelData[i].key])
          }else {
            this.TabelData[i].data = dataList[this.TabelData[i].key]
          }
        }
        console.log(this.TabelData);
      })
    },
    // 标签页事件
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      console.log('按钮的提交事件')
    },
    //  日期输入框的点击事件
    DateEvent(num) {
      this.show = true
      if (num == 1) {
        this.type = 1
      } else {
        this.type = 2
      }
    },
    //  日期选择的确定事件
    ConfirmEvent(value) {
      console.log(value)
      let Date = `${value.getFullYear()}-${
        value.getMonth() + 1
      }-${value.getDate()}`
      if (this.type == 1) {
        this.TimeOne = Date
      } else {
        this.TimeTwo = Date
      }
      this.show = false
    },
    //  日期选择的取消事件
    CancelEvent() {
      this.show = false
    },
  },
}
</script>

<style lang="less">
#TheTeamReport {
  width: 100%;
  height: 100%;
  .tabs-item {
    width: 100%;
    > .Text {
      height: 3.4375rem;
      line-height: 3.4375rem;
      text-align: center;
      color: #919191;
    }
  }
  .gridContent {
    .van-grid-item__content {
      > span:nth-child(1) {
        font-size: 0.75rem;
        color: #9c9c9c;
      }
      > span:nth-child(2) {
        font-size: 1.4rem;
        color: green;
      }
    }
  }
  .van-tabs {
    .van-tabs__wrap {
      border-bottom: 1px solid #eee;
    }
  }
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0;
    .Text {
      margin: 0 0.625rem;
      font-size: 0.875rem;
    }
    > .button-Time {
      display: block;
      text-align: center;
      width: 6.5rem;
      height: 1.875rem;
      line-height: 1.875rem;
      border-radius: 0.9375rem;
      font-size: 0.875rem;
      background: #f2f2f2;
    }
    > .van-button {
      height: 1.875rem;
      width: 5rem;
      border-radius: 0.9375rem;
      color: var(--textColor);
      background: #ff7614;
    }
    // 标签栏的
  }
}
</style>